import axios from "./index";

export default {
  getAll(page, perPage, keyword, status, isActive, sortBy, sortType) {
    return axios.get(`/v1/blog?page=${page}&perPage=${perPage}&keyword=${keyword}&status=${status}&isActive=${isActive}&sortBy=${sortBy}&sortType=${sortType}`);
  },
  getAllV2() {
    return axios.get("/v1/blog/all");
  },
  getDetail(uuid) {
    return axios.get(`/v1/blog/${uuid}`);
  },
  getAllTag() {
    return axios.get("/v1/blog/tags");
  },
  createBlog(data) {
    return axios.post("/v1/blog", data);
  },
  updateBlog(data) {
    return axios.put("/v1/blog", data);
  },
  sendApproveBlog(data) {
    return axios.post("/v1/blog/send_approval", data);
  },
  verifyBlog(data) {
    return axios.post("/v1/blog/verify_approval", data);
  },
  requestEditBlog(data) {
    return axios.put("/v1/blog/request_edit", data);
  },
  uploadFile(formData) {
    return axios.post(`/v1/blog/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  updateBlogHighlight(uuid) {
    return axios.patch(`/v1/blog/update_highlight/${uuid}`);
  },
  updateBlogInterested(uuid) {
    return axios.patch(`/v1/blog/update_interested/${uuid}`);
  },
  getBlogHighlight() {
    return axios.get("/v1/blog/highlight");
  },
  getBlogInterested() {
    return axios.get("/v1/blog/interested");
  },
  deleteBlog(uuid) {
    return axios.delete(`/v1/blog/${uuid}`);
  },
};
