import axios from "./index";

export default {
  getAll() {
    return axios.get("/v1/setting/categories");
  },
  update(uuid, data) {
    return axios.patch(`/v1/setting/categories/${uuid}`, data);
  },
  createField(data) {
    return axios.post("/v1/setting/categories/add_field", data);
  },
  updateField(data) {
    return axios.patch("/v1/setting/categories/update_field", data);
  },
  removeField(data) {
    return axios.patch("/v1/setting/categories/remove_field", data);
  },
  updateStatus(uuid) {
    return axios.patch(`/v1/setting/categories/${uuid}/update_status`);
  },
};
