<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import appConfig from "@/app.config";
import categorySvc from "@/services/category";
import fieldSvc from "@/services/field";
import blogSvc from "@/services/blog";
import { toast } from "vue3-toastify";
import draggable from "vuedraggable";
const _ = require("lodash");

/**
 * Customers component
 */
export default {
  page: {
    title: "Category",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, draggable },
  data() {
    return {
      title: "Category",
      items: [
        {
          text: "Setting",
          href: "#",
        },
        {
          text: "Category",
          active: true,
        },
      ],
      columns: [
        {
          field: "uuid",
          hidden: true,
        },
        {
          field: "fieldsDefault",
          hidden: true,
        },
        {
          field: "fieldsOptional",
          hidden: true,
        },
        {
          label: "Name",
          field: "name",
        },
        {
          label: "Fields (Default)",
          field: "fieldsDefaultDisplay",
        },
        {
          label: "Fields (Optional)",
          field: "fieldsOptionalDisplay",
        },
        {
          label: "Thumbnail",
          field: "thumbnail",
          hidden: true,
        },
        {
          label: "Status",
          field: "isActive",
        },
        {
          label: "Action",
          field: "button",
          html: true,
          sortable: false,
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
      rows: [],
      fields: [],
      defaultValueTrue: true,
      defaultValueFalse: false,
      fieldDefault: [],
      fieldOptional: [],
      fieldNotActive: [],
      showModalEdit: false,
      submitted: false,
      category: {
        uuid: "",
        title: "",
        thumbnail: "",
      },
    };
  },
  mounted() {
    this.get();
    this.getField();
  },
  methods: {
    get() {
      categorySvc.getAll().then((resp) => {
        if (resp.data.data === null) {
          return;
        }
        this.rows = resp.data.data.map((category) => {
          let fieldsDefault = [];
          if (category.fieldsDefault !== null) {
            fieldsDefault = category.fieldsDefault.map((field) => {
              if (field.isActive) {
                return field.fieldName;
              }
            });
          }

          if (fieldsDefault.length && typeof fieldsDefault[0] !== "undefined") {
            Object.assign(category, {
              fieldsDefaultDisplay: fieldsDefault.join(", "),
            });
          } else {
            Object.assign(category, { fieldsDefaultDisplay: "-" });
          }

          let fieldsOptional = [];
          if (category.fieldsOptional !== null) {
            fieldsOptional = category.fieldsOptional.map((field) => {
              if (field.isActive) {
                return field.fieldName;
              }
            });
          }

          if (
            fieldsOptional.length &&
            typeof fieldsOptional[0] !== "undefined"
          ) {
            Object.assign(category, {
              fieldsOptionalDisplay: fieldsOptional.join(", "),
            });
          } else {
            Object.assign(category, { fieldsOptionalDisplay: "-" });
          }

          return category;
        });
      });
    },
    getField() {
      fieldSvc.getAll().then((resp) => {
        this.fields = resp.data.data;
        resp.data.data.forEach((field) => {
          if (field.isActive) {
            if (field.fieldType === "default") {
              this.fieldDefault.push(field);
            } else {
              this.fieldOptional.push(field);
            }
          } else {
            this.fieldNotActive.push(field);
          }
        });
      });
    },
    edit(data) {
      this.category.title = data.name;
      this.category.uuid = data.uuid;
      this.category.thumbnail = data.thumbnail;
      this.fields.forEach((field) => {
        let isMatch = false;
        if (data.fieldsDefault !== null) {
          data.fieldsDefault.forEach((fieldsDefault) => {
            if (fieldsDefault.fieldUuid === field.uuid) {
              isMatch = true;
              Object.assign(field, {
                isCreate: false,
                fieldForCategoryIsActive: fieldsDefault.isActive,
                fieldForCategoryPriority: fieldsDefault.priority,
                fieldForCategoryDeleted: fieldsDefault.deletedAt ? true : false,
                fieldForCategoryIsRequired: fieldsDefault.isRequired,
              });
            }
          });
        }
        if (!isMatch && data.fieldsOptional !== null) {
          data.fieldsOptional.forEach((fieldsOptional) => {
            if (fieldsOptional.fieldUuid === field.uuid) {
              isMatch = true;
              Object.assign(field, {
                isCreate: false,
                fieldForCategoryIsActive: fieldsOptional.isActive,
                fieldForCategoryPriority: fieldsOptional.priority,
                fieldForCategoryDeleted: fieldsOptional.deletedAt
                  ? true
                  : false,
                fieldForCategoryIsRequired: fieldsOptional.isRequired,
              });
            }
          });
        }
        if (!isMatch) {
          Object.assign(field, {
            isCreate: true,
            fieldForCategoryIsActive: false,
            fieldForCategoryPriority: 0,
            fieldForCategoryDeleted: false,
            fieldForCategoryIsRequired: false,
          });
        }
      });

      this.fieldDefault = _.orderBy(
        this.fieldDefault,
        ["fieldForCategoryPriority"],
        ["asc"]
      );
      this.fieldOptional = _.orderBy(
        this.fieldOptional,
        ["fieldForCategoryPriority"],
        ["asc"]
      );
      this.fieldNotActive = _.orderBy(
        this.fieldNotActive,
        ["fieldForCategoryPriority"],
        ["asc"]
      );
      this.showModalEdit = true;
    },
    editStatus(uuid) {
      categorySvc
        .updateStatus(uuid)
        .then((resp) => {
          if (resp.status === 200) {
            toast.success("Update Status Successfully", {
              autoClose: localStorage.getItem("toastCountdown"),
            });
          } else {
            toast.error("Update Status Failed", {
              autoClose: localStorage.getItem("toastCountdown"),
            });
          }
        })
        .catch((err) => {
          toast.error(err, {
            autoClose: localStorage.getItem("toastCountdown"),
          });
        })
        .finally(() => {
          this.get();
        });
    },
    async handleSubmit() {
      const dataCategory = {
        thumbnail: this.category.thumbnail,
      };
      await categorySvc.update(this.category.uuid, dataCategory);

      let defaultPriority = 1;
      for (const field of this.fieldDefault) {
        const data = {
          categoryUUID: this.category.uuid,
          fieldUUID: field.uuid,
          priority: parseInt(defaultPriority),
          isRequired: field.fieldForCategoryIsRequired,
        };

        if (field.isCreate && field.fieldForCategoryIsActive) {
          await categorySvc.createField(data);
        } else if (!field.isCreate && field.fieldForCategoryIsActive) {
          await categorySvc.updateField(data);
          if (field.fieldForCategoryDeleted) {
            await categorySvc.removeField(data);
          }
        } else if (!field.isCreate && !field.fieldForCategoryIsActive) {
          await categorySvc.removeField(data);
        }
        defaultPriority++;
      }

      let optionalPriority = 1;
      for (const field of this.fieldOptional) {
        const data = {
          categoryUUID: this.category.uuid,
          fieldUUID: field.uuid,
          priority: parseInt(optionalPriority),
          isRequired: field.fieldForCategoryIsRequired,
        };

        if (field.isCreate && field.fieldForCategoryIsActive) {
          await categorySvc.createField(data);
        } else if (!field.isCreate && field.fieldForCategoryIsActive) {
          await categorySvc.updateField(data);
          if (field.fieldForCategoryDeleted) {
            await categorySvc.removeField(data);
          }
        } else if (!field.isCreate && !field.fieldForCategoryIsActive) {
          await categorySvc.removeField(data);
        }
        optionalPriority++;
      }

      toast.success("Updated Successfully", {
        autoClose: localStorage.getItem("toastCountdown"),
      });

      this.get();
      this.showModalEdit = false;
    },
    uploadThumbnail() {
      const formData = new FormData();

      for (const element of this.$refs.thumbnail.files) {
        let file = element;
        formData.append("images", file);
      }

      blogSvc
        .uploadFile(formData)
        .then((resp) => {
          this.category.thumbnail = resp.data.data[0].url;
        })
        .finally(() => {
          this.$refs.thumbnail.value = "";
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-12">
                <!-- MODAL SETTING-->
                <div class="text-sm-end">
                  <b-modal
                    v-model="showModalEdit"
                    scrollable
                    :title="`Manage Category : ${category.title}`"
                    title-class="text-black font-18"
                    body-class="p-3"
                    hide-footer
                  >
                    <form @submit.prevent="handleSubmit" item-key="id">
                      <h3>Thumbnail</h3>
                      <div class="col-lg-12">
                        <div class="upload-thumbnail" v-if="category.thumbnail">
                          <img
                            :src="category.thumbnail"
                            :alt="category.thumbnail"
                          />
                        </div>
                        <input
                          id="thumbnail"
                          ref="thumbnail"
                          type="file"
                          class="form-control"
                          accept="image/x-png,image/gif,image/jpeg"
                          @change="uploadThumbnail"
                        />
                      </div>
                      <hr />
                      <h3>Default</h3>
                      <div class="col-lg-12">
                        <div class="row header-drag-for-field">
                          <div class="col-md-5 fw-semibold">Field</div>
                          <div class="col-md-3 fw-semibold">Status</div>
                          <div class="col-md-3 fw-semibold">Required</div>
                          <div class="col-md-1"></div>
                        </div>
                      </div>
                      <div class="table-responsive mb-0">
                        <draggable v-model="fieldDefault" item-key="id">
                          <template #item="{ element }">
                            <div class="col-lg-12">
                              <div class="row body-drag-for-field">
                                <div class="col-md-5">
                                  {{ element.name }}
                                </div>
                                <div class="col-md-3">
                                  <b-form inline>
                                    <b-form-checkbox
                                      v-model="element.fieldForCategoryIsActive"
                                      name="check-button"
                                      switch
                                    >
                                      <span
                                        class="badge rounded-pill font-size-12 badge-soft-success"
                                        v-if="element.fieldForCategoryIsActive"
                                        >On</span
                                      >
                                      <span
                                        class="badge rounded-pill font-size-12 badge-soft-warning"
                                        v-else
                                        >Off</span
                                      >
                                    </b-form-checkbox>
                                  </b-form>
                                </div>
                                <div class="col-md-3">
                                  <b-form inline>
                                    <b-form-checkbox
                                      v-model="
                                        element.fieldForCategoryIsRequired
                                      "
                                      name="check-button"
                                      switch
                                      :disabled="
                                        !element.fieldForCategoryIsActive
                                      "
                                    >
                                      <span
                                        class="badge rounded-pill font-size-12 badge-soft-success"
                                        v-if="
                                          element.fieldForCategoryIsRequired
                                        "
                                        >On</span
                                      >
                                      <span
                                        class="badge rounded-pill font-size-12 badge-soft-warning"
                                        v-else
                                        >Off</span
                                      >
                                    </b-form-checkbox>
                                  </b-form>
                                </div>
                                <div class="col-md-1">
                                  <i class="fas fa-sort"></i>
                                </div>
                              </div>
                            </div>
                          </template>
                        </draggable>
                      </div>

                      <hr />
                      <h3>Optional</h3>
                      <div class="col-lg-12">
                        <div class="row header-drag-for-field">
                          <div class="col-md-5 fw-semibold">Field</div>
                          <div class="col-md-3 fw-semibold">Status</div>
                          <div class="col-md-3 fw-semibold">Required</div>
                          <div class="col-md-1"></div>
                        </div>
                      </div>
                      <div class="table-responsive mb-0">
                        <draggable v-model="fieldOptional" item-key="id">
                          <template #item="{ element }">
                            <div class="col-lg-12">
                              <div class="row body-drag-for-field">
                                <div class="col-md-5">
                                  {{ element.name }}
                                </div>
                                <div class="col-md-3">
                                  <b-form inline>
                                    <b-form-checkbox
                                      v-model="element.fieldForCategoryIsActive"
                                      name="check-button"
                                      switch
                                    >
                                      <span
                                        class="badge rounded-pill font-size-12 badge-soft-success"
                                        v-if="element.fieldForCategoryIsActive"
                                        >On</span
                                      >
                                      <span
                                        class="badge rounded-pill font-size-12 badge-soft-warning"
                                        v-else
                                        >Off</span
                                      >
                                    </b-form-checkbox>
                                  </b-form>
                                </div>
                                <div class="col-md-3">
                                  <b-form inline>
                                    <b-form-checkbox
                                      v-model="
                                        element.fieldForCategoryIsRequired
                                      "
                                      name="check-button"
                                      switch
                                      :disabled="
                                        !element.fieldForCategoryIsActive
                                      "
                                    >
                                      <span
                                        class="badge rounded-pill font-size-12 badge-soft-success"
                                        v-if="
                                          element.fieldForCategoryIsRequired
                                        "
                                        >On</span
                                      >
                                      <span
                                        class="badge rounded-pill font-size-12 badge-soft-warning"
                                        v-else
                                        >Off</span
                                      >
                                    </b-form-checkbox>
                                  </b-form>
                                </div>
                                <div class="col-md-1">
                                  <i class="fas fa-sort"></i>
                                </div>
                              </div>
                            </div>
                          </template>
                        </draggable>
                      </div>

                      <hr />
                      <h3>Not Active</h3>
                      <div class="col-lg-12">
                        <div class="row header-drag-for-field">
                          <div class="col-md-5 fw-semibold">Field</div>
                          <div class="col-md-3 fw-semibold">Status</div>
                          <div class="col-md-3 fw-semibold">Required</div>
                          <div class="col-md-1"></div>
                        </div>
                      </div>
                      <div class="table-responsive mb-0">
                        <draggable
                          v-model="fieldNotActive"
                          item-key="id"
                          :disabled="true"
                        >
                          <template #item="{ element }">
                            <div class="col-lg-12">
                              <div class="row body-drag-for-field">
                                <div class="col-md-5">
                                  {{ element.name }}
                                </div>
                                <div class="col-md-3">
                                  <b-form inline>
                                    <b-form-checkbox
                                      v-model="element.fieldForCategoryIsActive"
                                      name="check-button"
                                      switch
                                      disabled
                                    >
                                      <span
                                        class="badge rounded-pill font-size-12 badge-soft-success"
                                        v-if="element.fieldForCategoryIsActive"
                                        >On</span
                                      >
                                      <span
                                        class="badge rounded-pill font-size-12 badge-soft-warning"
                                        v-else
                                        >Off</span
                                      >
                                    </b-form-checkbox>
                                  </b-form>
                                </div>
                                <div class="col-md-3">
                                  <b-form inline>
                                    <b-form-checkbox
                                      v-model="
                                        element.fieldForCategoryIsRequired
                                      "
                                      name="check-button"
                                      switch
                                      :disabled="
                                        !element.fieldForCategoryIsActive
                                      "
                                    >
                                      <span
                                        class="badge rounded-pill font-size-12 badge-soft-success"
                                        v-if="
                                          element.fieldForCategoryIsRequired
                                        "
                                        >On</span
                                      >
                                      <span
                                        class="badge rounded-pill font-size-12 badge-soft-warning"
                                        v-else
                                        >Off</span
                                      >
                                    </b-form-checkbox>
                                  </b-form>
                                </div>
                                <div class="col-md-1">
                                  <i class="fas fa-sort"></i>
                                </div>
                              </div>
                            </div>
                          </template>
                        </draggable>
                      </div>

                      <div class="text-end pt-5 mt-3">
                        <b-button variant="light" @click="showModalEdit = false"
                          >Close</b-button
                        >
                        <b-button type="submit" variant="success" class="ms-1"
                          >Save</b-button
                        >
                      </div>
                    </form>
                  </b-modal>
                </div>
                <!-- MODAL SETTING-->
              </div>
              <!-- end col-->
            </div>
            <div class="table-responsive">
              <vue-good-table
                :search-options="{
                  enabled: true,
                }"
                :pagination-options="{
                  enabled: true,
                  perPage: 10,
                  mode: 'records',
                }"
                :line-numbers="true"
                styleClass="tableOne vgt-table striped"
                :columns="columns"
                :rows="rows"
              >
                <template #table-row="props">
                  <span v-if="props.column.field == 'button'">
                    <b-button
                      size="sm"
                      variant="success"
                      @click="edit(props.formattedRow)"
                    >
                      <i
                        class="mdi mdi-pencil font-size-16 align-middle me-2"
                      ></i>
                      Edit
                    </b-button>
                  </span>
                  <span v-else-if="props.column.field == 'isActive'">
                    <b-form-checkbox
                      v-model="props.formattedRow.isActive"
                      name="check-button"
                      switch
                      @change="editStatus(props.formattedRow.uuid)"
                    >
                      <span
                        class="badge rounded-pill font-size-12 badge-soft-success"
                        v-if="props.formattedRow.isActive"
                        >On</span
                      >
                      <span
                        class="badge rounded-pill font-size-12 badge-soft-warning"
                        v-else
                        >Off</span
                      >
                    </b-form-checkbox>
                  </span>
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>
              </vue-good-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
<style>
div.header-drag-for-field,
div.body-drag-for-field {
  margin-top: 10px;
  margin-bottom: 10px;
}
div.upload-thumbnail {
  width: auto;
  text-align: center;
  padding: 15px;
}
div.upload-thumbnail img {
  max-width: 100%;
  height: auto;
}
</style>
