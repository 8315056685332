import axios from "./index";

export default {
  getAll() {
    return axios.get("/v1/setting/fields");
  },
  create(data) {
    return axios.post("/v1/setting/fields", data);
  },
  update(data) {
    return axios.patch("/v1/setting/fields", data);
  },
  updateStatus(uuid) {
    return axios.patch(`/v1/setting/fields/${uuid}`);
  },
};
